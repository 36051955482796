import { useEffect, useState } from 'react'
import Layout from 'src/components/Layout'
import { getAllVerticals } from 'src/utils/contentful'
import { TextBody, H2 } from 'src/styles/Typography'
import Link from 'next/link'
import AccountsInput from 'src/components/Accounts/AccountsInput'
import { StyledButton } from 'src/styles/Button'
import Image from "next/legacy/image";
import { Auth } from 'aws-amplify'
import Router from 'next/router'
import Container from 'src/styles/Container'
import SignInHandler from 'src/components/Accounts/Handlers/SignInHandler'
import styled from 'styled-components'
import Toast from 'src/components/Accounts/Handlers/Toast'
import grabColor from 'src/utils/grabColor'

const TOAST_TTL = 5000

export default function SignIn({ verticals }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalid, setInvalid] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [color, setColor] = useState('')
  const [loading, setLoading] = useState(false)

  const toastHandler = (message, color) => {
    setShowToast(true)
    setMessage(message)
    setColor(color)
    const timer = setTimeout(function () {
      setShowToast(false)
    }, TOAST_TTL)
    return () => {
      clearTimeout(timer)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const user = await SignInHandler(email, password)
      if (user.invalid === true) {
        setLoading(false)
        setInvalid(true)
        toastHandler('Incorrect email or password', 'error')
      } else {
        Router.push('/account/dashboard')
      }
    } catch (e) {
      toastHandler(e.message, 'error')
      setLoading(false)
    }
  }

  const checkIfSignedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      Router.push('/')
    } catch (e) {}
  }
  useEffect(() => {
    checkIfSignedIn()
  }, [])
  return (
    <Layout verticals={verticals} footer={{ noBorder: true }} seo={{ title: 'Sign in' }}>
      <main>
        <Wrapper>
          <Container>
            <HeroWrapper>
              <InputContainer>
                <Title>
                  <span>Sign In</span>
                </Title>
                <SupportingText>
                  <TextBody>or</TextBody>
                  <LinkWrapper>
                    <Link href="/signup" passHref>
                      create your account
                    </Link>
                  </LinkWrapper>
                </SupportingText>
                <Form onSubmit={handleSubmit}>
                  <LoadingFieldset disabled={loading} aria-busy={loading}>
                    <InputWrapper>
                      <AccountsInput
                        name="emailValue"
                        required={false}
                        label="Email Address"
                        invalid={invalid}
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      ></AccountsInput>
                    </InputWrapper>
                    <InputWrapper>
                      <AccountsInput
                        name="passwordValue"
                        required={false}
                        label="Password"
                        type="password"
                        invalid={invalid}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      ></AccountsInput>
                    </InputWrapper>
                    <AnchorWrapper>
                      <StyledButton type="submit" tabIndex={0} size="large" width="100%">
                        Sign In
                      </StyledButton>
                    </AnchorWrapper>
                  </LoadingFieldset>
                </Form>
                <Wrap>
                  <SubLinkWrap>
                    <Link href="/forgot-password" passHref>
                      Forgot your password?
                    </Link>
                  </SubLinkWrap>
                </Wrap>
              </InputContainer>
              <ImageWrapper>
                <Image
                  src="/images/signIn.jpg"
                  alt="Sign In"
                  layout="fill"
                  priority
                  quality={10}
                />
              </ImageWrapper>
              {showToast ? <Toast message={message} color={color} /> : <></>}
            </HeroWrapper>
          </Container>
        </Wrapper>
      </main>
    </Layout>
  )
}

export async function getStaticProps() {
  const verticals = await getAllVerticals()

  return { props: { verticals } }
}

const Form = styled.form`
  width: 100%;
`

const LoadingFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 0;
  margin: 0;

  &[disabled] {
    opacity: 0.5;
  }
`

const AnchorWrapper = styled.div`
  padding-top: 1.5rem;
`

const Wrapper = styled.div`
  background: ${grabColor('backgroundLight')};
`

const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 32px;
  gap: 4px;
  margin-top: -100px;
  margin-bottom: 40px;

  max-width: 464px;
  height: 76px;

  background: ${grabColor('success.bg')};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 2px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: -0.5rem auto 2rem;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 80px;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    justify-content: left;
    padding: 1rem 0;
    width: 100%;
  }
  background-color: ${grabColor('backgroundLight')};
`

const InputWrapper = styled.div`
  padding-top: 1rem;
  display: ${(props) => props.shouldShow};
`

const HeroWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 0 80px;
  margin: 0 auto;
  background: ${grabColor('backgroundLight')};

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
    padding: 2rem 0;
  }
`

const SubLinkWrap = styled.div`
  display: flex;
  padding: 2rem;
  justify-content: center;

  a {
    color: black;
  }
`
const SupportingText = styled.div`
  text-decoration: none;
  display: flex;
  ${TextBody} {
    font-size: 16px;
  }
`
const LinkWrapper = styled.div`
  font-size: 16px;
  line-height: 3.58rem;
  padding-left: 5px;

  a {
    color: ${grabColor('action.secondary')};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`
const Title = styled(H2)``

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 3rem;
  margin: 0 auto;
  width: 100%;
  z-index: 2;
  img {
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
`

const Wrap = styled.div``
