import styled, { keyframes } from 'styled-components'
import { toast } from 'aws-amplify'

export default function Toast({ message, color }) {
  return <Container color={color}>{message}</Container>
}

const fadeIn = keyframes`
  0%{
    bottom: 0; 
    opacity: 0;
  }
  100%{
    bottom: 30px; 
    opacity: 1;
  }
`

const fadeOut = keyframes`
0% {bottom: 30px; opacity: 1;} 
  100% {bottom: 0; opacity: 0;}`

const Container = styled.div`
  display: flex;
  justify-content: center;
  visibility: visible;
  pointer-events: none;
  min-width: 250px;
  color: #fff;
  background-color: ${({ theme, color }) => `${theme.colors[color].DEFAULT}`};
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 10;
  bottom: 30px;
  font-size: 17px;
  animation: ${fadeIn} 0.3s ease, ${fadeOut} 0.3s ease 2.5s;
`
