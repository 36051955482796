import { Auth } from 'aws-amplify'
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'
const AuthUser = async (password, user) => {
  const { username, pool } = user

  const userPool = new CognitoUserPool({
    UserPoolId: pool.userPoolId,
    ClientId: pool.clientId,
  })
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  })

  cognitoUser.authenticateUser(authDetails, {
    onSuccess: () => {},
    onFailure: (error) => {
      throw `Auth Error ${error}`
    },
  })
}

export default AuthUser
