import { Auth } from 'aws-amplify'

const SignIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password)
    // If doesn't exist do nothing
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { requiredAttributes } = user.challengeParam
      const userAuthenticated = await Auth.completeNewPassword(user, newPassword)

      return userAuthenticated
    }
    return user
  } catch (error) {
    return false
  }
}

export default SignIn
