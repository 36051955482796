import { useState, useEffect } from 'react'
import styled from 'styled-components'
import grabColor from 'src/utils/grabColor'

function AccountsInput({
  autoComplete = 'new-password',
  name = 'empty-name',
  required = false,
  label,
  invalid = '',
  description = false,
  inline = false,
  type = 'text',
  ...props
}) {
  const [show, setShow] = useState(false)
  const restInputProps = {
    ...(invalid && { 'aria-invalid': true }),
    // ...(invalid && invalid.length && { 'aria-errormessage': `error-${id}` }),
    ...(description && { 'aria-describedby': `description-${name}` }),
    ...(label && { 'aria-labelledby': `labelby-${name}` }),
    ...props,
  }

  return (
    <Wrapper
      inline={inline}
      description={!!description}
      invalid={!!invalid}
      label={label}
    >
      {label && (
        <Label
          htmlFor={name}
          id={`labelby-${name}`}
          invalid={invalid}
          style={{ gridArea: 'label' }}
          inline={inline}
        >
          {label}
        </Label>
      )}
      <InputInline>
        <InputStyled
          autoComplete={autoComplete}
          type={show ? 'text' : type}
          id={name}
          name={name}
          required={required}
          {...restInputProps}
          style={{ gridArea: 'input' }}
        />
        {type === 'password' && (
          <ShowButton onClick={() => setShow((s) => !s)} type="button">
            {show ? 'Hide' : 'Show'}
          </ShowButton>
        )}
      </InputInline>
      {invalid && (
        <ErrorMessage id={`error-${name}`} style={{ gridArea: 'error' }} inline={inline}>
          {invalid}
        </ErrorMessage>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ inline, description, invalid, label }) =>
    inline &&
    `
    display: grid;
    grid-template-areas: '${label ? 'label' : '.'} input'
      '${description ? 'description' : '.'} ${invalid ? 'error' : '.'}';
    grid-template-rows: auto 24px;
    align-items: center;
    gap: 12px 24px;
    `}
`

const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.error.DEFAULT};
  margin: 0.5rem 0 0;
  ${({ inline }) => inline && `margin: 0;`}
`

const ShowButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 12px;
  height: 100%;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.action.DEFAULT};
  background: unset;
  border: unset;
  z-index: 20;
`
const InputInline = styled.div`
  position: relative;
  display: flex;
`
// needs to be at least 16px for mobile to not cause zoom
const InputStyled = styled.input`
  position: relative;
  z-index: 1;
  width: 100%;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderInput};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  height: 40px;
  color: ${({ theme }) => theme.colors.base};
  padding: 0 1rem;

  &::placeholder {
    font-family: Libre Franklin;
    font-size: 14px;
    line-height: 24px;
    color: ${grabColor('detail')};
  }

  &:invalid,
  &[aria-invalid='true'] {
    color: ${({ theme }) => theme.colors.error.DEFAULT};
    border: 1px solid #fca6a6;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.error.DEFAULT};

    &::placeholder {
      color: ${({ theme }) => theme.colors.error.DEFAULT};
    }
  }

  &[aria-invalid='true'] ~ ${ErrorMessage} {
    visibility: visible;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.backgroundMid};
  }
`

const Label = styled.label`
  font-family: Libre Franklin;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  min-height: 24px;
  color: ${({ theme, invalid }) =>
    invalid ? theme.colors.error.DEFAULT : theme.colors.base};
  margin: 0 0 12px 0;

  ${({ inline }) => inline && `margin: 0;`}

  &:invalid,
  &[aria-invalid='true'] {
    color: ${({ theme }) => theme.colors.error.DEFAULT};
  }
`

export default AccountsInput
