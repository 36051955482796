import AuthUser from 'src/components/Auth/AuthenticateUser'
import SignIn from 'src/components/Auth/SignIn'
import { Router } from 'next/router'
import { Amplify, Auth } from 'aws-amplify'

Amplify.configure(
  {
    aws_cognito_region: process.env.AWS_COGNITO_REGION,
    aws_cognito_identity_pool_id: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
    aws_user_pools_id: process.env.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  {
    Auth: {
      identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
      region: process.env.AWS_COGNITO_REGION,
    },
  },
)

Auth.configure({
  identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
  region: process.env.AWS_REGION,
})

const SignInHandler = async (username, password) => {
  try {
    const res = await SignIn(username, password)

    await AuthUser(password, res)

    return res
  } catch (e) {
    return { invalid: true, error: JSON.stringify(e) }
  }
}

export default SignInHandler
